<script setup>

</script>

<template>
    <div class="mt-24">
        <div class="flex flex-col md:flex-row sm:rounded-[30px] -mx-4 sm:mx-0 bg-teal-800">
            <div class="flex-1 flex-wrap">
                <div class="flex p-8 sm:p-16 flex-wrap flex-col">
                    <div class="text-gray-50 text-base font-semibold">{{ $t('Download mobile app') }}</div>
                    <div class="text-white text-2xl sm:text-4xl md:text-5xl font-medium font-lora mt-4">{{ $t('Stories are told not only at home') }}</div>
                    <div class="text-teal-300 text-xl font-normal leading-loose mt-5 mb-10">{{ $t('Enjoy the lives of people everywhere you go') }}</div>
                    <div class="flex flex-row gap-3">
                        <img class="max-h-11 max-w-[132px]" src="/storage/images/apple-store-badge.svg" alt="apple"/>
                        <img class="max-h-11 max-w-[132px]" src="/storage/images/android-store-badge.svg" alt="android"/>
                    </div>
                </div>
            </div>
            <div class="flex-1 flex justify-center items-center">
                <img class="max-h-[274px] object-contain" src="/storage/images/iPhone-14.png" alt="discount"/>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
